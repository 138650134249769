import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import { Card, Col, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Title from '../Ui/Typography/Title';
import Span from '../Ui/Typography/Span';
import webSite from '../assets/web-site.png';
import webSiteSmall from '../assets/web-site-small.png';
import webSiteSmallLeft from '../assets/port-hover-left2.png';
import webSiteSmallLeftBig from '../assets/port-hover-left-big.png';   
import webSiteBig from '../assets/port-hover-big.png';  
import webSiteMob from '../assets/port-hover-mob.png';  
import webSiteBigSm from '../assets/port-hover-big-sm.png';  
import webSiteMid from '../assets/port-hover-mid.png';   
import webSiteSm from '../assets/port-hover-sm.png';  
import webSiteSm2 from '../assets/port-hover-sm2.png';
import webSiteLeft from '../assets/port-hover-sm-left.png';
import Text from '../Ui/Typography/Text';
import Color from '../Ui/color';
import styled from 'styled-components';
import Section from '../components/Layout/Section';
import { media } from '../Ui/text-sizes';
import Colors from '../Ui/color';

const OurPortfolio = () => {
    const { allMarkdownRemark } = useStaticQuery(graphql`
        query OurPortfolio {
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portfolio/" } }) {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            id
                            img
                            logoBlack
                            slug
                            link
                        }
                    }
                }
            }
        }
`);

    const mainData = allMarkdownRemark.edges;

    return (
        <Container defKey='2' footer={Colors.offWhite}>
            <Section space></Section>
            <SEO title='Our portfolio' />
            <Section spaceTop={1} mobileTopSpace={0} spaceZero>
                <Col xs={24} sm={12} lg={13}>
                    <Title style={{padding:'10px'}} level={2} color='darkGray' bottomSpace={34} mobileBottomSpace={20} space={10} mobileTopSpace={10} letterMd>
                        We partner with founders to bring <Span type={'gradient2'}> <span className='bold'>ideas</span> </Span> to life.
                </Title>
                </Col>
                <StyledRow gutter={[30, 30]} justify={'center'}>
                    {mainData.map((item, index) => <Col xs={24} sm={12} lg={8}>
                        <Link key={index} to={`${item.node.frontmatter.link}`} target='blank'>
                            {/* <div style={{border:'1px solid red', height:'402px', backgroundColor:'#F6F6F6'}}> */}
                            <StyledCard>
                                <ImageLogo
                                    src={item.node.frontmatter.logoBlack}
                                />
                           
                            </StyledCard>
                            {/* <div style={{height:'80px', border:'1px solid green'}}><Text>See More</Text></div> */}
                            {/* </div> */}
                        </Link>
                    </Col>)}
                </StyledRow>
            </Section>
        </Container>
    )
};

export const StyledCard = styled(Card)`
   // height: 321px;
   height: 402px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    //border-right: 2px solid ${Color.black};
    
    border-radius: 0px;
    background: ${Color.offWhite};
    .ant-card-body {
        padding: 0 13px;
    }

    ${media.down('sm')} {
        margin-top: 5px !important;
        height: 375px;
    }
    
    &:hover {
        //transition: all 0.2s;
        // box-shadow: ${Color.shadow1};
        border-right: 2px solid ${Color.black};
        border-radius: 0px;
        padding-left: 2px;
       background-image: url(${webSite});
        background-repeat: no-repeat;
        .ImageLogo {
            display: none;
        }
        @media (max-width: 1130px) {
            background-image: url(${webSiteSmallLeft});
        }
        @media (max-width: 990px) {
            background-image: url(${webSiteSmallLeftBig});
        }
        @media (max-width: 900px) {
            background-image: url(${webSiteMid});
        }
        @media (max-width: 830px) {
            background-image: url(${webSite});
        }
        @media (max-width: 767px) {
            background-image: url(${webSiteSm});
        }
        @media (max-width: 700px) {
            background-image: url(${webSiteSm2});
        }
        @media (max-width: 650px) {
            background-image: url(${webSiteLeft});
        }
        @media (max-width: 575px) {
            background-image: url(${webSiteBig});
        }
        @media (max-width: 520px) {
            background-image: url(${webSiteBigSm});
        }
        @media (max-width: 520px) {
            background-image: url(${webSiteBigSm});
        }
        @media (max-width: 413px) {
            background-image: url(${webSiteMob});
        }
        @media (max-width: 396px) {
            background-image: url(${webSiteSmall});
        }
       
        // ${media.down('sm')} {
        //     margin-top: 5px !important;
        //     background-image: url(${webSiteSmall});
        // }
    }
    
`;

export const ImageLogo = styled.img`
    max-width: 100%;
    max-height: 80px; 
    // border:1px solid ${Color.black};
    // margin-top: 78px;
    // &:hover {
    //     padding-top: 20px;
    // }
`;

const StyledRow = styled(Row)`
    margin-top: 51px !important;
    
    ${media.down('sm')} {
        margin-top: 5px !important;

    }
`;

export default OurPortfolio;
